import { Injectable, signal } from '@angular/core';
import { IDraw, IDrawTable, IDrawBase, IDrawSchedule, WALLET_CODE, DRAW_SCHEDULE } from '@libs/interfaces';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { orderBy, find, assign } from 'lodash';
import { MapResponseData } from '@libs/rxjs/operators.rxjs';
import { MS_ONE_MIN } from '@libs/helper/constants.helper';
import { range } from 'lodash';
import { ONE_DAY_MILISECONDS } from '@libs/helper/constants.helper';
import { ApiService } from '../api.service';
import { LangService } from '../lang.service';
import { AuthService } from '../landing/auth.service';
import { ScheduleService } from './schedule-selector.service';
import { Animals2, GetPrizeGroup } from '@libs/helper/bicho/animals.helper';
import { GetTimestamp00Hours } from '@libs/helper/dates.helper';
import { WalletService } from '../wallet/wallet.service';
import { StoreBichoService } from '../store/store-bicho.service';
@Injectable({
  providedIn: 'root',
})
export class TableDrawsService {
  num_prev_days = 10;
  animals = signal([...Animals2()]);
  app = signal<'landing' | 'office'>('landing');
  constructor(
    private api: ApiService,
    private lang: LangService,
    private auth: AuthService,
    private scheduleService: ScheduleService,
    private walletService: WalletService,
    private storeBichoService: StoreBichoService,
  ) {}

  /**
   * Fetches and processes the draws for a specific day.
   * @param timestamp - The timestamp of the day to fetch draws for.
   * @returns An observable of the processed draws table.
   */
  dayDraws(timestamp: number, schedule: IDrawSchedule): Observable<IDrawTable> {
    return this.fetchDayDraws(timestamp, schedule).pipe(
      map((table: IDrawTable) => this.createDrawTable(table)),
      tap((drawsTable: IDrawTable) => this.storeBichoService.addDrawsTable(drawsTable)),
    );
  }

  /**
   * Fetches the draws for a specific day based on the user's login status.
   * @param timestamp - The timestamp of the day to fetch draws for.
   * @param schedule - The draw schedule.
   * @returns An observable of the draws table.
   */
  private fetchDayDraws(timestamp: number, schedule: IDrawSchedule): Observable<IDrawTable> {
    if (this.app() == 'office') return this.fetchDayDrawsOffice(timestamp, schedule);
    if (this.auth.logged()) return this.fetchDayDrawsOfUser(timestamp, schedule);
    return this.fetchDayDrawsNotLogged(timestamp, schedule);
  }

  /**
   * Fetches the draws for a specific day for the office.
   * @param timestamp - The timestamp of the day to fetch draws for.
   * @param schedule - The draw schedule.
   * @returns An observable of the draws table.
   */
  private fetchDayDrawsOffice(timestamp: number, schedule: IDrawSchedule) {
    return this.api.get(`office/draws/day/${schedule.denom}/${timestamp}`).pipe(
      MapResponseData,
      map((draws: IDraw[]) => ({ draws: draws, timestamp: timestamp, schedule: schedule })),
    );
  }

  /**
   * Fetches the draws for a specific day for a logged-in user.
   * @param timestamp - The timestamp of the day to fetch draws for.
   * @param schedule - The draw schedule.
   * @returns An observable of the draws table.
   */
  private fetchDayDrawsOfUser(timestamp: number, schedule: IDrawSchedule) {
    return this.api
      .get(`draws/day/${schedule.denom}/${timestamp}/${this.walletService.wallet()?.code || WALLET_CODE.BRL}`)
      .pipe(
        MapResponseData,
        map((draws: IDraw[]) => ({ draws: draws, timestamp: timestamp, schedule: schedule })),
      );
  }

  /**
   * Fetches the draws for a specific day for a user who is not logged in.
   * @param timestamp - The timestamp of the day to fetch draws for.
   * @param schedule - The draw schedule.
   * @returns An observable of the draws table.
   */
  private fetchDayDrawsNotLogged(timestamp: number, schedule: IDrawSchedule) {
    return this.api.get(`draws/day/not-logged/${schedule.denom}/${timestamp}`).pipe(
      MapResponseData,
      map((draws: IDraw[]) => ({ draws: draws, timestamp: timestamp, schedule: schedule })),
    );
  }

  /**
   * Creates a draw table by parsing the provided table.
   * @param table - The table to parse.
   * @returns The parsed draw table.
   */
  private createDrawTable(table: IDrawTable): IDrawTable {
    return this.parseTable(this.parse(table));
  }

  /**
   * Parses the draw table and processes each draw.
   * @param dt - The draw table to parse.
   * @returns The parsed draw table.
   */
  private parseTable(dt: IDrawTable): IDrawTable {
    dt.draws.forEach(draw => this.parseDraw(draw));
    return dt;
  }

  /**
   * Parses the draw table and completes all prizes and animals.
   * @param table - The draw table to parse.
   * @returns The parsed draw table.
   */
  private parse(table: IDrawTable): IDrawTable {
    if (table.draws.length > 0) {
      const draws = orderBy(table.draws, ['draw_timestamp'], ['asc']);
      table.draws = draws.map((draw: IDraw) => this.completeAllPrizesAnimals(draw));
    }
    return assign(table, { lang: this.lang.lang });
  }

  /**
   * Parses a single draw and updates its properties.
   * @param draw - The draw to parse.
   * @returns The parsed draw.
   */
  parseDraw(draw: IDraw): IDraw {
    const drawsBase: IDrawBase[] = this.scheduleService.drawsBase();
    if (!draw) return draw;
    const denom = (draw.schedule as any)?.denom;
    const schedule_denom: DRAW_SCHEDULE = denom >= 0 ? denom : draw.schedule;
    draw.drawBase = drawsBase.find((db: IDrawBase) => db.denomination == draw.denomination);
    const schedule = find((draw.drawBase as any).schedules, s => s.denomination == schedule_denom);
    draw.hour = (draw.drawBase as any).label;
    draw.prevMinAcceptBet = schedule.prevMinAcceptBet || 0;
    draw.limit_bet_timestamp = draw.draw_timestamp - (draw.prevMinAcceptBet as any) * MS_ONE_MIN;
    return draw;
  }

  /**
   * Completes all prizes and animals for a draw.
   * @param draw - The draw to complete.
   * @returns The draw with completed prizes and animals.
   */
  private completeAllPrizesAnimals(draw: IDraw): IDraw {
    if (draw.result) {
      const prizes: any = draw.result.prizes;
      const allPrizes = [...prizes.prizes, prizes.moderno, prizes.rio, prizes.salteado];
      return assign(draw, {
        result: assign(draw.result, {
          allPrizes: allPrizes,
          allAnimals: allPrizes.map(
            (number: string, index: number) => this.animals[index == 7 ? +number - 1 : GetPrizeGroup(number) - 1],
          ),
        }),
      });
    } else return draw;
  }

  /**
   * Generates an array of timestamps for the previous days.
   * @returns An array of timestamps.
   */
  daysTimestamps(): number[] {
    return range(-1, this.num_prev_days, 1).map((i: number) =>
      GetTimestamp00Hours(Date.now() - i * ONE_DAY_MILISECONDS),
    );
  }

  /**
   * Calculates the next minimum bet time for a draw.
   * @param draw - The draw to calculate for.
   * @returns The next minimum bet time in minutes.
   */
  private nextMinBet(draw: IDraw): number {
    const limit_bet_timestamp = draw?.limit_bet_timestamp;
    return Math.floor((limit_bet_timestamp - Date.now()) / MS_ONE_MIN);
  }

  /**
   * Checks if it is not possible to place a bet on a draw.
   * @param draw - The draw to check.
   * @returns True if it is not possible to place a bet, false otherwise.
   */
  noPosibleBet(draw: IDraw): boolean {
    const diff = this.nextMinBet(draw);
    return diff < 0;
  }

  /**
   * Checks if the next bet time is less than 15 minutes.
   * @param draw - The draw to check.
   * @returns True if the next bet time is less than 15 minutes, false otherwise.
   */
  nextLess15MinBet(draw: IDraw): boolean {
    const diff = this.nextMinBet(draw);
    return diff >= 0 && diff <= 15;
  }
}
